@import './assets/scss/_vars';

* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
  color: $color_text_main;
  font-family: 'Nunito Sans';
  font-size: 18px;
  line-height: 1.4;
}

#root {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: var(--size-font-headline);
  line-height: 1.2;
  margin: 1em 0 0.5em;
}

.app {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  &.start {
    .action_screens {
      animation: fade_in 1s ease-out forwards;
    }
  }
}

@keyframes fade_in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.anim {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

img.icon {
  max-width: var(--size-icon);
}

.action_screens {
  position: relative;
  user-select: none; // ansonsten markiert man dauernd versehentlicht assets, wenn man gedrückt hält
}

.action_screen {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  &.hidden {
    display: none;
  }
  // DEBUG STYLES
  &.debug {
    opacity: 0.2;
    &:nth-of-type(1) {
      background-color: red;
    }
    &:nth-of-type(2) {
      background-color: blue;
    }
    &:nth-of-type(3) {
      background-color: green;
    }
    &:nth-of-type(4) {
      background-color: purple;
    }
    &:nth-of-type(5) {
      background-color: peru;
    }
    &:nth-of-type(6) {
      background-color: pink;
    }
    &:nth-of-type(7) {
      background-color: orange;
    }
    &:nth-of-type(8) {
      background-color: orchid;
    }
  }
}

.action_point,
.swipe_prompt,
.asset_overlay,
.info_text {
  position: absolute;
  transform: translate(-50%, -50%);
}

.info_text {
  color: $color_info_text;
  text-shadow: rgba(0, 0, 0, 0.6) 0 0 80px;
  font-size: var(--size-font-headline-extra);
  text-align: center;
  width: 80%;
  max-width: var(--text-max-width);
}

.action_point,
.swipe_prompt {
  top: 50%;
  left: 50%;
  width: 5%;
  aspect-ratio: 1;
  border-radius: 100%;
  cursor: pointer;
}

.action_point {
  background-color: $color_action_point;
  pointer-events: auto;
  animation: action_point_anim 2s infinite;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 5px solid $color_action_point;
    box-sizing: border-box;
    border-radius: 100%;
    animation: action_point_ring_anim 2s infinite;
  }
}

.swipe_prompt {
  border: 10px solid $color_action_point;
  animation: swipe_prompt_right_anim 3s infinite;
  &.go_left {
    animation-name: swipe_prompt_left_anim;
  }
  &.go_up {
    animation-name: swipe_prompt_up_anim;
  }
  &.go_down {
    animation-name: swipe_prompt_down_anim;
  }
}

@keyframes action_point_anim {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
  }
  40% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) scale(0.8);
  }
}

@keyframes action_point_ring_anim {
  0% {
    transform: scale(1);
    border-width: 5px;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    border-width: 0;
    opacity: 0;
  }
}

@keyframes swipe_prompt_right_anim {
  0% {
    transform: translateX(-50%) scale(1.2);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    transform: translateX(-50%) scale(0.8);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateX(-200%) scale(0.8);
    opacity: 0;
  }
}

@keyframes swipe_prompt_left_anim {
  0% {
    transform: translateX(-50%) scale(1.2);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    transform: translateX(-50%) scale(0.8);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateX(100%) scale(0.8);
    opacity: 0;
  }
}

@keyframes swipe_prompt_up_anim {
  0% {
    transform: translateY(-50%) scale(1.2);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    transform: translateY(-50%) scale(0.8);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(100%) scale(0.8);
    opacity: 0;
  }
}

@keyframes swipe_prompt_down_anim {
  0% {
    transform: translateY(-50%) scale(1.2);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    transform: translateY(-50%) scale(0.8);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(-200%) scale(0.8);
    opacity: 0;
  }
}

.sidebar_wrapper {
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: background 0.5s ease;
  .sidebar_overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sidebar {
    position: absolute;
    width: var(--width-sidebar);
    min-height: 100%;
    top: 0;
    right: 0;
    transform: translateX(100%);
    padding: var(--spacing);
    background: $color_sidebar_bg;
    pointer-events: all;
    transition: transform 0.5s ease-in-out;
    img {
      width: 100%;
      height: auto;
    }
    .icon.sidebar_close {
      margin-bottom: var(--spacing);
      &:hover {
        cursor: pointer;
      }
    }
  }
  &.open {
    background: rgba(0, 0, 0, 0.6);
    pointer-events: all;
    .sidebar {
      transform: translateX(0);
    }
  }
}

.progress_bar {
  position: fixed;
  display: flex;
  top: var(--spacing);
  left: 50%;
  width: 100%;
  max-width: 640px;
  transform: translateX(-50%);
}

.progress_bar_segment {
  flex: 1;
  margin: 0 5px;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  background-color: $color_progress_bar_bg;
  @include drop_shadow();
  &:hover {
    cursor: pointer;
  }
}

.progress_bar_segment_fill {
  height: 100%;
  background-color: $color_progress_bar_fill;
}

.play_button {
  position: fixed;
  bottom: var(--spacing);
  right: var(--spacing);
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
  @include drop_shadow();
  transition: transform 0.25s ease-in-out;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}

.app {
  touch-action: none;
}

.anim {
  pointer-events: none;
}
